
const buildLayoutConfig = function (layoutString) {

    if(!layoutString) return {};
    
    const layoutObject = JSON.parse(layoutString);

    let layoutConfig = {};

    let configKeys = Object.keys(layoutObject);

    for (const ck of configKeys) {
        let configValues = Object.values(layoutObject[ck]);
        let configTransformed = [];

        for (var i = 0; i < configValues.length; i++) {
            configTransformed.push(
                {
                    i: configValues[i].INSIGHT_KEY, 
                    x: configValues[i].X_COORD, 
                    y: configValues[i].Y_COORD, 
                    w: configValues[i].WIDTH, 
                    h: configValues[i].HEIGHT
                });
        }

        layoutConfig[ck] = configTransformed;
    }
    
    // console.log("insight dashboard", layoutConfig);

    return layoutConfig;
}

export {buildLayoutConfig};

