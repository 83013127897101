import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import HelpIcon from '@material-ui/icons/Help';
import PersonIcon from '@material-ui/icons/Person';
import TimerIcon from '@material-ui/icons/Timer';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import DevicesIcon from '@material-ui/icons/Devices';

import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import BusinessIcon from '@material-ui/icons/Business';

import UnfoldMore from '@material-ui/icons/UnfoldMore';
import UnfoldLess from '@material-ui/icons/UnfoldLess';
import BallotIcon from '@material-ui/icons/Ballot';

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import More from '@material-ui/icons/MoreHoriz';
import PieChartIcon from '@material-ui/icons/PieChart';
import StoreIcon from '@material-ui/icons/Store';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import InsertComment from '@material-ui/icons/InsertComment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';
import ExtensionIcon from '@material-ui/icons/Extension'; 
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import GavelIcon from '@material-ui/icons/Gavel';
import GroupIcon from '@material-ui/icons/Group';
import SettingsIcon from '@material-ui/icons/Settings';
import SearchIcon from '@material-ui/icons/Search';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import AirlineSeatReclineExtraIcon from '@material-ui/icons/AirlineSeatReclineExtra';
import RefreshIcon from '@material-ui/icons/Refresh';
import StarIcon from '@material-ui/icons/StarBorder';
import CameraIcon from '@material-ui/icons/Camera';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import CreateIcon from '@material-ui/icons/Create';
import StarsIcon from '@material-ui/icons/Stars';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CancelIcon from '@material-ui/icons/Cancel';
import FlightTakeoffRoundedIcon from '@material-ui/icons/FlightTakeoffRounded';

import TableChartIcon from '@material-ui/icons/TableChart';
import ViewDayIcon from '@material-ui/icons/ViewDay';

import EditIcon from '@material-ui/icons/Edit'; 
import ClearIcon from '@material-ui/icons/HighlightOff';
import GridOnIcon from '@material-ui/icons/GridOn';
import SaveIcon from '@material-ui/icons/Save';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'; 
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import AddCircleIcon from '@material-ui/icons/AddCircle'; 
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import DeleteIcon from '@material-ui/icons/Delete';

import PrintIcon from '@material-ui/icons/Print';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';

import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import NoteAddIcon from '@material-ui/icons/NoteAdd';

import AddBoxIcon from '@material-ui/icons/AddBox';
import AddIcon from '@material-ui/icons/Add';

import CheckIcon from '@material-ui/icons/Check';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

import FlightIcon from '@material-ui/icons/Flight'; 
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
//import AddIcCallIcon from '@material-ui/icons/AddIcCall';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';


import FirstPageIcon from '@material-ui/icons/FirstPage';
import PreviousPageIcon from '@material-ui/icons/NavigateBefore';
import NextPageIcon from '@material-ui/icons/NavigateNext';
import LastPageIcon from '@material-ui/icons/LastPage';
import HomeIcon from '@material-ui/icons/Home';

import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import LanguageIcon from '@material-ui/icons/Language';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import GetAppIcon from '@material-ui/icons/GetApp';

//https://v4.mui.com/components/material-icons/
const InfiniteLoop = AllInclusiveIcon;
const IconLeftArrow = KeyboardArrowLeftIcon;
const DrowDownArrow = ArrowDropDownIcon;
const IconBell = NotificationsIcon;
const IconHelp = HelpIcon;
const Info = InfoIcon;
const IconPerson = PersonIcon;
const IconCompany = BusinessIcon;
const ExpandLessIcon = IconExpandLess;
const ExpandMoreIcon = IconExpandMore;
const FoodIcon =FastfoodIcon;
const ForwardArrowIcon = ArrowForwardIcon;
const FilledCancelIcon = CancelIcon;

const Icon={
    'home':HomeIcon,
    'company':BusinessIcon,
    'report':PieChartIcon,
    'purchase':StoreIcon,
    'sales':EqualizerIcon,
    'announcement':AnnouncementIcon,
    'describe':InsertComment,
    'warehouse':AccountBalanceIcon,
    'voucher':DescriptionIcon,
    'expense':DescriptionIcon,
    'resources':ExtensionIcon,
    'project':AccountTreeIcon,
    'prod':GavelIcon,
    'relations':GroupIcon,
    'settings':SettingsIcon,
    'person':IconPerson,
    'more':More,
    'defaultTick':CheckCircleIcon,
    'appTick':MobileFriendlyIcon,
    'cost':AccountBalanceWalletIcon,
    'timesheet':TimerIcon,
    'hold':EmojiPeopleIcon,
    'fav':StarsIcon,

    'approve':ThumbUpIcon,
    'reject':ThumbDownIcon,

    'issue':AssignmentIcon,
    'return':AssignmentReturnIcon,
    
    'expand':IconExpandMore,
    'collapse':IconExpandLess,
    
    'variation':BallotIcon,
    'unfold':UnfoldMore,
    'fold':UnfoldLess,

    'high':ArrowUpward,
    'low':ArrowDownward,

    'tech':DevicesIcon,

    search : SearchIcon,
    save : SaveIcon,
    delete : DeleteIcon,
    print : PrintIcon,
    copy : VerticalSplitIcon,
    add : AddBoxIcon,
    adds : AddIcon,
    edit: EditIcon,
    create : NoteAddIcon,
    confirm: CheckCircleIcon,
    next : OpenInNewIcon,
    import : SystemUpdateAltIcon,
    cancel : SettingsBackupRestoreIcon,
    refresh: RefreshIcon,
    star: StarIcon,
    download: GetAppIcon,
    tick:CheckIcon,
    attach:AttachFileIcon,
    resume:PlayCircleOutlineIcon,
    flightBooking:FlightIcon,
    account:GridOnIcon,
    task:PlaylistAddCheckIcon,
    heplpdesk:PlaylistAddCheckIcon,
    amc:PlaylistAddCheckIcon,
    "backArrow":ChevronLeftIcon,
    "forwardArrow":ChevronRightIcon,
    "pfirst":FirstPageIcon,
    "plast":LastPageIcon,
    "pprev":PreviousPageIcon,
    "pnext":NextPageIcon,
    "clear":ClearIcon,
    "birdeye":CameraIcon,
    "restaurantMenu":RestaurantMenuIcon,
    "info":InfoIcon
}

const FlightBookingStepsIcons = {
    1:SearchIcon,
    2:PersonIcon,
    3:ShoppingCartIcon,
    4:AccountBalanceWalletIcon,
    5:DescriptionIcon,
    'search':SearchIcon,
    'personDetails':PersonIcon,
    'addons':ShoppingCartIcon,
    'payment':AccountBalanceWalletIcon,
    'summary':DescriptionIcon
}

const Addons ={
    'meal' : FastfoodIcon,
    'baggage':LocalMallIcon,
    'seat':AirlineSeatReclineExtraIcon
}

export default Icon
export {HomeIcon, StarIcon, Icon, FlightBookingStepsIcons,Addons,
    IconLeftArrow, LanguageIcon,IconCompany,Info,DrowDownArrow,InfiniteLoop,
    IconBell,IconHelp,IconPerson,ExpandLessIcon,ExpandMoreIcon,FoodIcon,ForwardArrowIcon,FilledCancelIcon,
    FlightTakeoffRoundedIcon, TableChartIcon,ExtensionIcon,ViewDayIcon,EditIcon,ClearIcon,GridOnIcon,SaveIcon,ArrowBackIosIcon,
    ArrowForwardIosIcon,AddCircleIcon,RemoveCircleIcon,CreateIcon,CheckIcon,ChevronLeftIcon,ChevronRightIcon

};
