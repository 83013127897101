
import InsightBarGraphView from './components/InsightBarGraphView'
import InsightColumnGraphView from './components/InsightColumnGraphView'


//Make sure this only contains insights related component
const colViewMap = {
    "vInsightViewBarGraph" : InsightBarGraphView,
    "vInsightViewColumnGraph" : InsightColumnGraphView
}

export default colViewMap;
