import React, {useEffect, useState} from 'react';
import useFormApi from '../docAutomation/useFormApi'
import SarasRadioButton from '../../../library/input/radioBox/SarasRadioButton1';
import SarasTrans from '../../../library/notifications/SarasTrans';
import ActionButton from '../../../library/input/buttons/button/SarasButton2';

const InsightsView = (props) => {
    const { viewData, viewMap } = props;
    const [insightData, setInsightData] = useState(null);
    const [insightOption, setInsightOption] = useState(null);
    const [insightViewKey, setInsightViewKey] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const formApi = useFormApi();

    const loadView = () => {
        if(!insightData) {
            // onRefreshViewClick();
            let layoutAction = { actionId: "show", actionName: "appInsight", 
                actionParam: 
                {
                    'content-type':'insight-view',
                    'view-key': viewData.dataList.vk
                }, 
                containerKey:""};
            formApi.runGenericAction(layoutAction, "appInsight", null, null, null, "", "", 0, onViewSuccess);
        }
    };

    useEffect(loadView, [insightData, insightViewKey]);

    const onViewSuccess = (action, containerKey, rowKey, headerKey, response) => {
        // console.log("layout response view data", response);

        setIsLoading(false);

        if(response.mainViewDataV5){
            const _insightViewData = JSON.parse(response.mainViewDataV5.viewData);
            const _insightViewKey = response.mainViewDataV5.viewKey;
            
            setInsightData(_insightViewData);
            setInsightViewKey(_insightViewKey);
            setInsightOption(_insightViewData.DefaultOption);
        }
    }

    const onEditViewClick = () => {

    }

    const onRefreshViewClick = () => {
        setIsLoading(true);

        let layoutAction = { actionId: "show", actionName: "appInsight", 
            actionParam: 
            {
                'content-type':'insight-view',
                'view-key': viewData.dataList.vk
            }, 
            containerKey:""};

        formApi.runGenericAction(layoutAction, "appInsight", null, null, null, "", "", 0, onViewSuccess);
    }

    const onDownloadViewClick = () => {

    }

    const onChangeOptionClick = (radioRef, value, isChecked) => {
        setInsightOption(value);
    }

    // console.log("layout response view", viewData.dataList.vk);
    const renderDynamicView = () => {
        let DynamicComponent = viewMap[insightViewKey];

        if(!DynamicComponent)
        {
            DynamicComponent = viewMap["vInsightGraphView"];
        }

        if(!DynamicComponent)
        {
            return null;
        }
        else
        {
            return (
                <DynamicComponent viewData={insightData} activeOption={insightOption}/>
            )
        }
    }

    const renderActionView = () => {
        return (
            <div>
                <div className='uiInsightViewActionLeft'>
                {
                    Object.keys(insightData.ViewData).map((row, index) => (
                        <span key={index}>
                            <SarasRadioButton 
                                name="insight-option" value={row} checked={row === insightOption ? true: false} 
                                onChange={onChangeOptionClick}></SarasRadioButton>
                            <label className="uiVal"><SarasTrans value={row} /></label>
                        </span>
                    ))
                }
                </div>
                <div className='uiInsightViewActionRight'>
                    {
                        insightData.EnableDownload === 1 ?
                            <ActionButton onClick={onDownloadViewClick} text="Download Data" icon="download" 
                                    tooltip="Download the insights data"
                                    className="uiHoverRound uiLink uiSpacerH5"/>
                        : null
                    }
                    {
                        insightData.EnableCriteriaEditor === 1 ?
                        <ActionButton onClick={onEditViewClick} text="Edit Criteria" icon="edit" 
                            tooltip="Edit the insights criteria"
                            className="uiHoverRound uiLink uiSpacerH5"/>
                            : null
                    }
                    <ActionButton onClick={onRefreshViewClick} text="Reload Data" 
                            tooltip="Refresh insights data"
                            icon="refresh" className={`${isLoading ? 'uiRotating' : ''} uiHoverRound uiLink uiSpacerH5`} />
                </div>
            </div>
        );
    }

    if(insightData)
    {
        return (
            <div className='uiInsightView uiInsightBox'>
                {
                    insightData.EnableActionView === 1 ?
                        <div className='uiInsightViewAction'>
                            {renderActionView()}
                        </div>
                    : null
                }
                <div className={`${insightData.EnableActionView === 1 ? 'uiInsightViewContent' : 'uiInsightViewContentNoAction'}`}>
                    {renderDynamicView()}
                </div>
            </div>
        )
    }

    return null;
}

export default InsightsView;
